document.addEventListener("DOMContentLoaded", () => {


    const thumbs = document.querySelectorAll('.thumb');
    const body = document.body;

    ThumbRandomPositioning(thumbs);

    document.body.addEventListener('click', function (e) {
        body.classList.toggle('noThumbs');
        if(body.classList.contains('noThumbs')){
            setTimeout(function(){
                ThumbRandomPositioning(thumbs);
                thumbs.forEach((thumb) => {
                    thumb.classList.remove('visible');
                });
            }, 300)
        }
        if (e.target.tagName === "IMG") {
            thumbs.forEach((thumb) => {
                thumb.classList.toggle('visible');
            });
        }

    });

    window.addEventListener('resize', debounce(function () {
        ThumbRandomPositioning(thumbs);

    }, 250));
    window.addEventListener('resize', () => {
        thumbs.forEach((thumb) => {
            body.classList.remove('noThumbs');
            thumb.classList.remove('visible');
        });
    });

});


function ThumbRandomPositioning(thumbs) {
    thumbs.forEach((thumb) => {
        const Xmax = window.innerWidth - thumb.offsetWidth;
        const Ymax = window.innerHeight - thumb.offsetHeight;
        const posX = getRandomArbitrary(0, Xmax) + "px";
        const posY = getRandomArbitrary(75, Ymax) + "px";

        thumb.style.top = posY;
        thumb.style.left = posX;
        thumb.classList.add('visible');
    });
}


function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

function debounce(func, delay) {
    let timeoutId;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
}